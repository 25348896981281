import { get, omitBy, pick } from 'lodash';

import OnDemand2 from '@@src/@types/OnDemand2';
import { components } from '@@types/CatalogueApi';
import OnDemand from '@@types/OnDemand';
import { IMAGE_HOST } from '@@utils/constants';

import { ShelfRow, RowV2, GridRow } from '../components/PageRows/PageRow';
import { generatePathFromLinkProps } from '../routes';
import {
  transformEntityTypeToSeriesType,
  transformEntityType,
  getRoute,
  getImageIdByProperties,
  transformAvailability,
  transformClassification, transformSponsorship,
  transformEntityTypeToProgramType,
} from './CatalogApiCommonTransformer';

interface CollectionItemSeries {
  id: string;
  title: string;
  entityType: string;
  slug: string;
  classificationID?: string;
  seasonCount: number;
  orphanEpisodeCount?: number;
  genres: string[];
  images: {
    id: string;
    category: string;
  }[];
}

// New Transformation Functions
export function transformCollectionItemSeriesV2(item: components['schemas']['collectionItemSeries'], imageRatio: OnDemand2.ImageRatios = '16:9'): OnDemand2.CollectionItemSeries {
  const seriesType = transformEntityTypeToSeriesType(item.entityType);
  const availability = transformAvailability(item.availability);

  let seasonCount = 0;
  if (item.seasonCount) {
    seasonCount = item.seasonCount;
  }

  return {
    id: item.id,
    entityType: 'Series',
    catalogueEntityType: item.entityType,
    seriesType,
    title: item.title,
    description: item.description,
    slug: item.slug,
    seasonCount,
    // genre: item.genres[0],
    genres: item.genres,
    availableDate: availability.availableDate,
    isAvailable: availability.available,
    language: item.languages[0],
    imageId: getImageIdByProperties(item.images, { ratio: imageRatio }),
    route: getRoute(item),
    classificationId: 'classificationID' in item ? transformClassification(item.classificationID) : null,
  };
}

type CommonVideoType =
  | components['schemas']['collectionItemMovie']
  | components['schemas']['collectionItemEpisode']
  | components['schemas']['collectionItemClip']
  | components['schemas']['collectionItemProgram'];

export function transformCollectionItemCommonVideoPropsV2(item: CommonVideoType, imageRatio: OnDemand2.ImageRatios = '16:9'): OnDemand2.CollectionItemVideoBase {
  const availability = transformAvailability(item.availability);

  return {
    id: item.id,
    title: item.title,
    description: item.description,
    slug: item.slug,
    duration: item.duration,
    mpxMediaId: item.mpxMediaID.toString(),
    genres: item.genres,
    classificationId: 'classificationID' in item ? transformClassification(item.classificationID) : null,
    releaseYear: get(item, 'releaseYear', null),
    availableDate: availability.availableDate,
    isAvailable: availability.available,
    language: 'languages' in item ? item.languages[0] : null,
    imageId: getImageIdByProperties(item.images, { ratio: imageRatio }),
    route: getRoute(item),
    cdpTitle: item.cdpTitle,
  };
}

export function transformCollectionItemMovieV2(item: components['schemas']['collectionItemMovie'], imageRatio: OnDemand2.ImageRatios = '2:3'): OnDemand2.CollectionItemMovie {
  const movieItem = transformCollectionItemCommonVideoPropsV2(item, imageRatio);

  return {
    ...movieItem,
    entityType: 'Movie',
    catalogueEntityType: item.entityType,
    progressSeconds: item.progressSeconds,
    progressPercent: item.progressPercent,
    progressDecoration: item.progressDecoration,
  };
}

export function transformCollectionItemProgramV2(item: components['schemas']['collectionItemProgram'], imageRatio: OnDemand2.ImageRatios = '16:9'): OnDemand2.CollectionItemProgram {
  const programItem = transformCollectionItemCommonVideoPropsV2(item, imageRatio);
  const programType = transformEntityTypeToProgramType(item.entityType);
  const availability = transformAvailability(item.availability);

  return {
    ...programItem,
    entityType: 'Program',
    catalogueEntityType: item.entityType,
    programType,
    isLiveStream: item.liveStream,
    expiredDate: availability.expiredDate,
    availableDate: availability.availableDate,
    progressSeconds: item.progressSeconds,
    progressPercent: item.progressPercent,
    progressDecoration: item.progressDecoration,
  };
}

export function transformCollectionItemEpisodeV2(item: components['schemas']['collectionItemEpisode'], imageRatio: OnDemand2.ImageRatios = '16:9'): OnDemand2.CollectionItemEpisode {
  const episodeItem = transformCollectionItemCommonVideoPropsV2(item, imageRatio);

  const seriesType = transformEntityTypeToSeriesType(item.entityType);

  return {
    ...episodeItem,
    entityType: 'Episode',
    catalogueEntityType: item.entityType,
    seriesType,
    seriesId: item.seriesID,
    seriesTitle: item.seriesTitle,
    seriesSlug: item.seriesSlug,
    seasonSlug: item.seasonSlug,
    seasonNumber: item.seasonNumber,
    episodeNumber: item.episodeNumber,
    progressSeconds: item.progressSeconds,
    progressPercent: item.progressPercent,
    progressDecoration: item.progressDecoration,
  };
}

export function transformCollectionItemClipV2(item: components['schemas']['collectionItemClip'], imageRatio: OnDemand2.ImageRatios = '16:9'): OnDemand2.CollectionItemClip {
  const clipItem = transformCollectionItemCommonVideoPropsV2(item, imageRatio);

  return {
    ...clipItem,
    entityType: 'Clip',
    catalogueEntityType: item.entityType,
  };
}

type CollectionItemCollectionAndPageProps = {
  id: string;
  title: string;
  slug: string;
  description: string;
  imageId: string;
  route: OnDemand2.OdLinkProps;
};

export function transformCollectionItemCommonProps(item: components['schemas']['collectionItemCollection'] | components['schemas']['collectionItemPage']): CollectionItemCollectionAndPageProps {
  return {
    id: item.id,
    title: item.title,
    slug: item.slug,
    description: item.description,
    imageId: item.images[0].id,
    route: getRoute(item),
  };
}

export function transformCollectionItemCollectionV2(item: components['schemas']['collectionItemCollection']): OnDemand2.CollectionItemCollection {
  const collectionItem = transformCollectionItemCommonProps(item);

  return {
    ...collectionItem,
    entityType: 'Collection',
    catalogueEntityType: item.entityType,
  };
}

export function transformCollectionItemPageV2(item: components['schemas']['collectionItemPage']): OnDemand2.CollectionItemPage {
  const pageItem = transformCollectionItemCommonProps(item);

  return {
    ...pageItem,
    entityType: 'Page',
    catalogueEntityType: item.entityType,
  };
}

// Old Transformation Functions
export function transformCollectionItemSeries(item: CollectionItemSeries, imageRatio = '16:9'): OnDemand.Tile {
  const metadata = [];

  if (item.genres.length) {
    metadata.push(item.genres[0]);
  }

  const distributors = get(item, 'distributors', []) as OnDemand.Distributor[];

  let seasonCount = 0;
  if (item.seasonCount) {
    seasonCount = item.seasonCount;
  }
  if ('orphanEpisodeCount' in item && item.orphanEpisodeCount > 0) {
    seasonCount += 1;
  }

  const extraMetadata = [];
  if (seasonCount) {
    extraMetadata.push({
      key: 'metadata.seasonsAvailable',
      params: {
        count: seasonCount,
      },
    });
  }

  return {
    id: item.id,
    item: {
      id: item.id,
      title: item.title,
      type: 'TVSeries',
      // collection item is assumed to always be available
      available: true,
      expired: false,
      genres: item.genres,
      classification: 'classificationID' in item ? transformClassification(item.classificationID) : null,
      route: {
        name: 'series',
        params: {
          seriesType: transformEntityTypeToSeriesType(item.entityType),
          slug: item.slug,
        },
      },
      distributors,
    },
    metadata,
    extraMetadata,
    imageProps: {
      imageId: getImageIdByProperties(item.images, { ratio: imageRatio }),
      alt: item.title,
      title: item.title,
      ratio: imageRatio,
      srcSetWidths: [300, 600],
      imageSizes: { all: 300 },
    },
  };
}

export function transformCollectionItemCollection(item: components['schemas']['collectionItemCollection'], imageRatio): OnDemand.Tile<OnDemand.Collection> {
  return {
    id: item.id,
    item: {
      id: item.id,
      type: 'Collection',
      title: item.title,
      description: item.description,
      route: {
        to: generatePathFromLinkProps(getRoute(item)),
      },
    },
    imageProps: {
      imageId: get(item.images, '0.id'),
      alt: item.title,
      title: item.title,
      ratio: imageRatio,
      srcSetWidths: [340, 780],
      imageSizes: { all: 340 },
    },
    extraMetadata: [],
    metadata: [],
  };
}

export function transformCollectionItemPage(item: components['schemas']['collectionItemPage'], imageRatio: string): OnDemand.Tile<OnDemand.Page> {
  return {
    id: item.id,
    item: {
      id: item.id,
      type: 'Page',
      title: item.title,
      description: item.description,
      route: {
        to: generatePathFromLinkProps(getRoute(item)),
      },
    },
    imageProps: {
      imageId: get(item.images, '0.id'),
      alt: item.title,
      title: item.title,
      ratio: imageRatio,
      srcSetWidths: [340, 780],
      imageSizes: { all: 340 },
    },
    extraMetadata: [],
    metadata: [],
  };
}

interface CollectionItemVideoBase {
  id: string;
  title: string;
  entityType: string;
  slug: string;
  classificationID?: string;
  genres?: string[];
  releaseYear?: number;
  availability: {
    start: string;
    end: string;
  };
  mpxMediaID: number;
  liveStream?: boolean;
  images: {
    id: string;
    category: string;
  }[];
}

interface CollectionItemMovie extends CollectionItemVideoBase {
  entityType: 'MOVIE';
}

interface CollectionItemProgram extends CollectionItemVideoBase {
  entityType: 'TV_PROGRAM' | 'SPORTS_PROGRAM' | 'NEWS_PROGRAM';
}

interface CollectionItemClip extends CollectionItemVideoBase {
  entityType: 'CLIP';
}

interface CollectionItemEpisode extends CollectionItemVideoBase {
  entityType: 'TV_EPISODE' | 'SPORTS_EPISODE' | 'NEWS_EPISODE';
  seriesTitle: string;
  episodeNumber: number;
  seasonNumber: number;
  seriesSlug: string;
  seasonSlug?: string;
}

type CollectionItemVideo = CollectionItemMovie | CollectionItemProgram | CollectionItemClip | CollectionItemEpisode;

export function transformCollectionItemVideo(item: CollectionItemVideo, imageRatio = '2:3'): OnDemand.Tile {
  const metadata = [];
  const extraMetadata = [];

  if (item.entityType === 'TV_EPISODE'
    || item.entityType === 'SPORTS_EPISODE'
    || item.entityType === 'NEWS_EPISODE'
  ) {
    metadata.push(item.seriesTitle);
  } else {
    if (item.genres?.length) {
      metadata.push(item.genres[0]);
    }

    if ('releaseYear' in item && item.releaseYear) {
      extraMetadata.push(item.releaseYear.toString());
    }
  }

  const availability = transformAvailability(item.availability);

  if (item.entityType === 'TV_EPISODE') {
    extraMetadata.push(`S${item.seasonNumber} E${item.episodeNumber}`);
  }

  const distributors = get(item, 'distributors', []) as OnDemand.Distributor[];

  return {
    id: item.id,
    item: {
      id: item.mpxMediaID.toString(),
      title: item.title,
      mediaTitle: item.title,
      type: transformEntityType(item.entityType),
      available: availability.available,
      expired: availability.expired,
      availableDate: availability.availableDate,
      expiredDate: availability.expiredDate,
      classification: 'classificationID' in item ? transformClassification(item.classificationID) : null,
      genres: item.genres,
      route: getRoute(item),
      isLiveStream: 'liveStream' in item ? item.liveStream : false,
      odImageId: getImageIdByProperties(item.images, { ratio: imageRatio }) ?? item.images[0].id,
      distributors,
    },
    metadata,
    extraMetadata,
    imageProps: {
      imageId: getImageIdByProperties(item.images, { ratio: imageRatio }) ?? item.images[0].id,
      alt: item.title,
      title: item.title,
      ratio: imageRatio,
      srcSetWidths: [300, 600],
      imageSizes: { all: 300 },
    },
  };
}

export type PageSectionCollection = {
  id: string;
  title: string;
  entityType: 'CURATED_COLLECTION' | 'DYNAMIC_COLLECTION';
  displayType: '16x9' | '2x3' | 'TALL' | '1x1_MASK';
  items: (
    | components['schemas']['collectionItemMovie']
    | components['schemas']['collectionItemSeries']
    | components['schemas']['collectionItemEpisode']
    | components['schemas']['collectionItemProgram']
    | components['schemas']['collectionItemClip']
    | components['schemas']['collectionItemCollection']
    | components['schemas']['collectionItemPage']
  )[];
  slug: string;
};

export function transformPageSectionCollectionToRowV2<T extends PageSectionCollection>(collection: T, rowType: 'shelf' | 'grid', imageRatio: OnDemand2.ImageRatios = '2:3'): RowV2 {
  const items = collection.items.map((item): OnDemand2.CollectionItem => {
    if (item.entityType === 'TV_SERIES' || item.entityType === 'SPORTS_SERIES' || item.entityType === 'NEWS_SERIES') {
      return transformCollectionItemSeriesV2(item, imageRatio);
    }

    if (item.entityType === 'TV_PROGRAM' || item.entityType === 'SPORTS_PROGRAM' || item.entityType === 'NEWS_PROGRAM') {
      return transformCollectionItemProgramV2(item, imageRatio);
    }

    if (item.entityType === 'MOVIE') {
      return transformCollectionItemMovieV2(item, imageRatio);
    }

    if (item.entityType === 'TV_EPISODE' || item.entityType === 'SPORTS_EPISODE' || item.entityType === 'NEWS_EPISODE') {
      return transformCollectionItemEpisodeV2(item, imageRatio);
    }

    if (item.entityType === 'CLIP') {
      return transformCollectionItemClipV2(item, imageRatio);
    }

    if (item.entityType === 'CURATED_COLLECTION' || item.entityType === 'DYNAMIC_COLLECTION') {
      return transformCollectionItemCollectionV2(item);
    }

    if (item.entityType === 'PAGE') {
      return transformCollectionItemPageV2(item);
    }

    return null;
  });

  let displayType: '16:9' | '2:3' = imageRatio;
  if (collection.displayType === '16x9') {
    displayType = '16:9';
  } else if (collection.displayType === '2x3') {
    displayType = '2:3';
  }

  let type;
  if (rowType === 'grid') {
    type = 'gridV2';
  } else if (rowType === 'shelf') {
    type = 'shelfV2';
  } else if (rowType === 'list') {
    type = 'listV2';
  }

  return {
    id: collection.id,
    collection: {
      ...collection,
      layout: rowType,
      displayType,
      items,
      route: getRoute(collection),
    },
    type,
  };
}

export type PartialCollection = Pick<components['schemas']['collection'], 'id' | 'title' | 'items' | 'displayType' | 'slug' | 'layout'> & {
  entityType: 'CURATED_COLLECTION' | 'DYNAMIC_COLLECTION' | 'PERSONALISED_COLLECTION' | 'HERO_COLLECTION' | 'PAGE';
};

export function transformCollectionToShelfOrGridRow<T extends PartialCollection>(collection: T, rowType: 'shelf' | 'grid'): ShelfRow | GridRow;
export function transformCollectionToShelfOrGridRow<T extends PartialCollection>(collection: T, rowType: 'shelfV2' | 'gridV2' | 'listV2', recommendationId?: string, recommendationVariant?: string): RowV2;
export function transformCollectionToShelfOrGridRow<T extends PartialCollection>(collection: T, rowType: 'shelf' | 'grid' | 'shelfV2' | 'gridV2' | 'listV2', recommendationId?: string, recommendationVariant?: string): ShelfRow | GridRow | RowV2 {
  if (rowType === 'shelf' || rowType === 'grid') {
    let imageRatio;
    let itemType;
    if (collection.displayType === 'TALL') {
      imageRatio = '2:3';
      itemType = 'collection';
    } else {
      imageRatio = '1:1';
      itemType = 'circle';
    }
    const items = collection.items.map((item): OnDemand.Tile => {
      if (item.entityType === 'TV_SERIES' || item.entityType === 'SPORTS_SERIES' || item.entityType === 'NEWS_SERIES') {
        return transformCollectionItemSeries(item, imageRatio);
      }

      if (item.entityType === 'MOVIE'
      || item.entityType === 'TV_EPISODE'
      || item.entityType === 'SPORTS_EPISODE'
      || item.entityType === 'NEWS_EPISODE'
      || item.entityType === 'TV_PROGRAM'
      || item.entityType === 'SPORTS_PROGRAM'
      || item.entityType === 'NEWS_PROGRAM'
      || item.entityType === 'CLIP'
      ) {
        return transformCollectionItemVideo(item, imageRatio);
      }

      if (item.entityType === 'CURATED_COLLECTION' || item.entityType === 'DYNAMIC_COLLECTION') {
        return transformCollectionItemCollection(item, imageRatio);
      }

      if (item.entityType === 'PAGE') {
        return transformCollectionItemPage(item, imageRatio);
      }
      return null;
    });

    return {
      id: collection.id,
      name: collection.title,
      type: rowType,
      items,
      itemType,
      displayType: collection.displayType,
      totalItems: items.length,
      layout: collection.layout,
      route: {
        name: 'collection',
        params: {
          slug: collection.slug,
        },
      },
    };
  }

  const items = collection.items.map((item): OnDemand2.CollectionItem => {
    let imageRatio: OnDemand2.ImageRatios;
    if (collection.displayType) {
      if (collection.displayType === '2x3') {
        imageRatio = '2:3';
      } else if (collection.displayType === '16x9') {
        imageRatio = '16:9';
      }
    }

    let transformedItem = null;

    if (item.entityType === 'TV_SERIES' || item.entityType === 'SPORTS_SERIES' || item.entityType === 'NEWS_SERIES') {
      transformedItem = transformCollectionItemSeriesV2(item, imageRatio);
    }

    if (item.entityType === 'MOVIE') {
      transformedItem = transformCollectionItemMovieV2(item, imageRatio);
    }

    if (item.entityType === 'TV_PROGRAM' || item.entityType === 'SPORTS_PROGRAM' || item.entityType === 'NEWS_PROGRAM') {
      transformedItem = transformCollectionItemProgramV2(item, imageRatio);
    }

    if (item.entityType === 'TV_EPISODE' || item.entityType === 'SPORTS_EPISODE' || item.entityType === 'NEWS_EPISODE') {
      transformedItem = transformCollectionItemEpisodeV2(item, imageRatio);
    }

    if (item.entityType === 'CLIP') {
      transformedItem = transformCollectionItemClipV2(item, imageRatio);
    }

    if (item.entityType === 'CURATED_COLLECTION' || item.entityType === 'DYNAMIC_COLLECTION') {
      transformedItem = transformCollectionItemCollectionV2(item);
    }

    if (item.entityType === 'PAGE') {
      transformedItem = transformCollectionItemPageV2(item);
    }

    if (!transformedItem) {
      return null;
    }

    return {
      ...transformedItem,
      ...((recommendationId) && {
        recommendationId,
        recommendationVariant,
      }),
    };
  });

  // set the new layout type
  let layout;
  if (rowType === 'gridV2') {
    layout = 'grid';
  } else if (rowType === 'shelfV2') {
    layout = 'shelf';
  } else if (rowType === 'listV2') {
    layout = 'list';
  }

  // set the displayType
  let displayType: any = '2:3'; // default to 2:3 display type
  if (collection.displayType === '16x9') {
    displayType = '16:9';
  } else if (collection.displayType === 'LIVE_SCHEDULE' && layout === 'list') {
    displayType = 'list';
  } else if (collection.displayType === 'LIVE_SCHEDULE' && layout !== 'list') {
    displayType = 'LIVE_SCHEDULE';
  }

  return {
    id: collection.id,
    type: rowType,
    collection: {
      id: collection.id,
      layout,
      title: collection.title,
      displayType,
      items,
      route: {
        name: 'collection',
        params: {
          slug: collection.slug,
        },
      },
    },
  };
}

export function transformRecommendedItems(
  recommendedItems: (
    components['schemas']['collectionItemMovie']
    | components['schemas']['collectionItemSeries']
    | components['schemas']['collectionItemEpisode']
    | components['schemas']['collectionItemProgram']
  )[],
  imageRatio: '16:9' | '2:3',
  recommendationId: string = undefined,
  recommendationVariant: string = undefined,
): OnDemand2.CollectionItem[] {
  return recommendedItems.map((item): OnDemand2.CollectionItem => {
    let transformedRecItem = null;

    if (item.entityType === 'TV_SERIES' || item.entityType === 'SPORTS_SERIES' || item.entityType === 'NEWS_SERIES') {
      transformedRecItem = transformCollectionItemSeriesV2(item, imageRatio);
    }

    if (item.entityType === 'MOVIE') {
      transformedRecItem = transformCollectionItemMovieV2(item, imageRatio);
    }

    if (item.entityType === 'TV_PROGRAM' || item.entityType === 'SPORTS_PROGRAM' || item.entityType === 'NEWS_PROGRAM') {
      transformedRecItem = transformCollectionItemProgramV2(item, imageRatio);
    }

    if (item.entityType === 'TV_EPISODE' || item.entityType === 'NEWS_EPISODE' || item.entityType === 'SPORTS_EPISODE') {
      transformedRecItem = transformCollectionItemEpisodeV2(item, imageRatio);
    }

    if (transformedRecItem === null) {
      return null;
    }

    return {
      ...transformedRecItem,
      ...((recommendationId) && {
        recommendationId,
        recommendationVariant,
      }),
    };
  }).filter((item) => {
    return !!item;
  });
}

export function transformRecommendedCollectionToShelf<T extends PartialCollection>(collection: T, rowType: 'shelfV2', imageRatio: '16:9' | '2:3', recommendationId?: string, recommendationVariant?: string): RowV2 {
  // filter out episodes and add livestream prop
  const filteredCollection = collection.items.map((item) => {
    if (item.entityType === 'MOVIE' || item.entityType === 'TV_PROGRAM' || item.entityType === 'SPORTS_PROGRAM' || item.entityType === 'NEWS_PROGRAM' || item.entityType === 'TV_SERIES' || item.entityType === 'SPORTS_SERIES' || item.entityType === 'NEWS_SERIES') {
      return item;
    }
    return null;
  });
  const items = transformRecommendedItems(filteredCollection, imageRatio, recommendationId, recommendationVariant);

  let displayType;
  if (collection.displayType === '16x9') {
    displayType = '16:9';
  } else if (collection.displayType === '2x3') {
    displayType = '2:3';
  }

  return {
    id: collection.id,
    type: rowType,
    collection: {
      id: collection.id,
      layout: 'shelf',
      title: collection.title,
      displayType,
      items,
      // recommendation collection does not have a route
      route: null,
    },
  };
}

export const transformCollectionToCollectionPage = (collection: components['schemas']['collection']): OnDemand.CollectionPage => {
  const heroImage = {};
  let ogImage;

  ['16:9', '2:3'].forEach((ratio) => {
    const imageId = getImageIdByProperties(collection.images, { type: 'KEY_ART', ratio });
    if (imageId) {
      heroImage[ratio] = `${IMAGE_HOST}/${imageId}`;
    }
  });

  const imageId = getImageIdByProperties(collection.images, { type: 'BANNER', ratio: '16:9' });
  if (imageId) {
    ogImage = `${IMAGE_HOST}/${imageId}`;
  }

  // sort the filters and remove single value filters for type / language
  function transformAllFilters(filters: Record<string, string[]>): Record<string, string[]> {
    const filterKeys = ['type', 'language', 'subtitle', 'audio'];
    return omitBy(pick(filters, filterKeys), (value, key) => {
      if (key === 'type' || key === 'language') {
        return value.length <= 1;
      }
      return Array.isArray(value) && value.length === 0;
    });
  }

  const sponsorship = transformSponsorship(collection.sponsorships);

  let rowType;
  if (collection.layout === 'GRID') {
    if (collection.displayType === '1x1_MASK' || collection.displayType === 'TALL') {
      rowType = 'grid';
    } else {
      rowType = 'gridV2';
    }
  } else if (collection.layout === 'CAROUSEL') {
    if (collection.displayType !== '1x1_MASK' && collection.displayType !== 'TALL') {
      rowType = 'shelfV2';
    } else {
      rowType = 'shelf';
    }
  } else if (collection.layout === 'LIST') {
    rowType = 'listV2';
  }

  return {
    title: collection.title,
    row: transformCollectionToShelfOrGridRow(collection, rowType),
    meta: collection.meta,
    allFilters: transformAllFilters(collection.allFilters),
    filters: collection.filters,
    description: collection.description,
    heroImage,
    ogImage,
    sponsorship,
  };
};

export default transformCollectionToCollectionPage;
