import { AppBar, Toolbar } from '@material-ui/core';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import config from '@@utils/config';

import appStoreImage from '../../../static/images/app-store.png';
import contoursImage from '../../../static/images/contours-ltr.png';
import contoursImageRtl from '../../../static/images/contours-rtl.png';
import facebookImage from '../../../static/images/facebook.svg';
import googlePlayImage from '../../../static/images/google-play.png';
import instagramImage from '../../../static/images/instagram.svg';
import logoImage from '../../../static/images/logo.png';
import XLogo from '../../../static/images/x-logo.png';
import { RtlThemeProvider } from '../../ProviderWrapper';
import { isRtl } from '../../i18n';
import blue from '../../styles/colors/blue';
import grey from '../../styles/colors/grey';
import fontFamily from '../../styles/typography/fontFamily';
import OdContainer from '../Utils/OdContainer';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      paddingTop: 40,
      backgroundColor: grey.blackpearl,
      marginTop: theme.spacing(10),
      paddingBottom: theme.spacing(4),
      backgroundImage: `url(${contoursImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right bottom',
      backgroundSize: '10vw',
      [theme.breakpoints.up('xs')]: {
        backgroundSize: 128,
      },
      zIndex: 1,
    },
    appBar: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      justifyContent: 'center',
    },
    topAppBar: {
      fontFamily: fontFamily.secondary,
      fontSize: '1.125rem',
      marginTop: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.25rem',
      },
      '& a': {
        color: grey.zumthor,
      },
    },
    bottomAppBar: {
      fontSize: '0.875rem',
      marginTop: theme.spacing(4),
      borderTop: '1px solid rgb(47, 52, 56)',
      '& a': {
        color: grey.zumthor,
      },
      [theme.breakpoints.only('sm')]: {
        marginTop: theme.spacing(3),
        paddingLeft: 25,
        paddingRight: 25,
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(3),
        paddingLeft: 45,
        paddingRight: 45,
      },
    },
    toolBar: {
      justifyContent: 'center',
      minHeight: 40,
      flexWrap: 'wrap',
    },
    logo: {
      width: 229,
      [theme.breakpoints.down('md')]: {
        width: 202,
      },
      [theme.breakpoints.down('sm')]: {
        width: 189,
      },
      display: 'block',
      margin: 'auto',
      '& img': {
        width: '100%',
      },
      '&:focus': {
        outline: `3px solid ${blue.navy}`,
      },
    },
    menuItem: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        textAlign: 'center',
      },
      '& a': {
        display: 'inline-block',
        padding: '0 20px',
        [theme.breakpoints.down('md')]: {
          padding: '0 12px',
        },
      },
      '& a span': {
        display: 'inline-block',
        padding: '10px 0',
        [theme.breakpoints.down('xs')]: {
          padding: '8px 0',
        },
      },
      '&:first-child a': {
        [theme.breakpoints.up('md')]: {
          paddingLeft: 0,
        },
      },
      '&:last-child a': {
        [theme.breakpoints.up('md')]: {
          paddingRight: 0,
        },
      },
      '& img': {
        display: 'block',
      },
      '& a:focus': {
        outline: `3px solid ${blue.navy}`,
      },
    },
    linkMenuItemContainer: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    appMenuItemContainer: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        marginLeft: 40,
      },
      '& a': {
        padding: '0 8px',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(5),
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        flexWrap: 'wrap',
      },
    },
    appMenuItem: {
      [theme.breakpoints.down('sm')]: {
        marginTop: 10,
        marginBottom: 10,
      },
      '& img': {
        height: 40,
        [theme.breakpoints.down('sm')]: {
          height: 50,
        },
      },
    },
    socialMenuItemContainer: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        marginLeft: 40,
      },
      [theme.breakpoints.only('sm')]: {
        marginLeft: 30,
        marginTop: 40,
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(2),
      },
      '& a': {
        padding: '0 8px',
      },
    },
    socialMenuItem: {
      [theme.breakpoints.down('sm')]: {
        marginTop: 10,
        marginBottom: 10,
      },
    },
    bottomMenuItemsContainer: {
      display: 'flex',
      paddingTop: theme.spacing(2),
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        maxWidth: '700px',
        lineHeight: '16px',
      },
    },
    footerText: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 12,
        paddingRight: 12,
        marginTop: theme.spacing(3),
      },
      color: alpha(grey.chateau, 0.8),
      fontSize: '0.875rem',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        textAlign: 'center',
      },
      '& span': {
        marginTop: 5,
        marginBottom: 5,
      },
      '& span + span': {
        [theme.breakpoints.up('md')]: {
          marginLeft: theme.spacing(4),
        },
        [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacing(2),
        },
      },
    },
  });
});

const Footer = () => {
  const classes = useStyles({});
  const { t, i18n: { language } } = useTranslation('common');

  const menuItemsTop: {
    key: string;
    url?: string;
    path?: string;
  }[] = [
    {
      key: 'exploreSbs',
      url: 'https://www.sbs.com.au/',
    }, {
      key: 'help',
      url: 'https://help.sbs.com.au/hc/en-au/categories/360000172615',
    }, {
      key: 'contactUs',
      url: 'https://help.sbs.com.au/hc/en-au/requests/new',
    },
  ];

  const menuItemsBottom = [
    {
      key: 'audioDescription',
      url: 'https://www.sbs.com.au/aboutus/audio-description-services',
    },
    {
      key: 'copyright',
      url: 'https://www.sbs.com.au/aboutus/copyright',
    },
    {
      key: 'termsAndConditions',
      url: 'https://www.sbs.com.au/terms',
    },
    {
      key: 'privacy',
      url: 'https://www.sbs.com.au/privacy',
    },
    {
      key: 'yourOnlinePreferences',
      url: 'https://www.sbs.com.au/preferences',
    },
    {
      key: 'salesAndAdvertising',
      url: 'https://www.sbs.com.au/sales',
    },
    {
      key: 'complaints',
      url: 'https://www.sbs.com.au/complaints',
    },
    {
      key: 'sbsCodesOfPractice',
      url: 'https://www.sbs.com.au/aboutus/sbs-codes-of-practice',
    },
    {
      key: 'careers',
      url: 'https://www.sbs.com.au/careers',
    },
    {
      key: 'aboutSbs',
      url: 'https://www.sbs.com.au/aboutus',
    },
  ];

  function renderMenuItems(menuItems) {
    return menuItems.map((menuItem) => {
      const contentText = t(`footer.${menuItem.key}`);
      let content;
      if (menuItem.image) {
        content = <img src={menuItem.image} alt={contentText} style={menuItem.imageStyle}/>;
      } else {
        content = <span>{contentText}</span>;
      }

      let link;
      if (Object.prototype.hasOwnProperty.call(menuItem, 'path')) {
        link = <Link to={menuItem.path}>{content}</Link>;
      } else {
        link = <a href={menuItem.url} target="_blank" rel="noopener noreferrer">{content}</a>;
      }

      return (
        <div key={menuItem.key} className={classes.menuItem}>
          {link}
        </div>
      );
    });
  }

  return (
    /* check if contoursImageRtl exist so that its included in the build */
    <footer className={classes.root} dir={isRtl(language) && contoursImageRtl ? 'rtl' : 'ltr'}>
      <OdContainer>
        <Link to="/" className={classes.logo} aria-label="SBS On Demand">
          <img src={logoImage} alt=""/>
        </Link>
        <AppBar component="div" color="default" position="static" className={clsx(classes.appBar, classes.topAppBar)}>
          <Toolbar disableGutters className={classes.toolBar}>
            <div className={classes.linkMenuItemContainer}>
              {renderMenuItems(menuItemsTop)}
            </div>
            <div className={classes.appMenuItemContainer}>
              <div className={clsx(classes.menuItem, classes.appMenuItem)}>
                <a
                  href="https://itunes.apple.com/au/app/sbs-on-demand/id542090992?mt=8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={appStoreImage} alt={t('footer.appStore')}/>
                </a>
              </div>
              <div className={clsx(classes.menuItem, classes.appMenuItem)}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.sbs.ondemand.android&hl=en_AU&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={googlePlayImage} alt={t('footer.googlePlayStore')}/>
                </a>
              </div>
            </div>
            <div className={classes.socialMenuItemContainer}>
              <div className={clsx(classes.menuItem, classes.socialMenuItem)}>
                <a
                  href="https://x.com/sbsondemand"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Follow SBS On Demand on X (Twitter)"
                >
                  <img src={XLogo} alt={t('footer.x')} style={{ height: 45 }}/>
                </a>
              </div>
              <div className={clsx(classes.menuItem, classes.socialMenuItem)}>
                <a
                  href="https://www.facebook.com/sbsondemand"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebookImage} alt={t('footer.facebook')} style={{ height: 45 }}/>
                </a>
              </div>
              <div className={clsx(classes.menuItem, classes.socialMenuItem)}>
                <a
                  href="https://www.instagram.com/sbsondemand/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={instagramImage} alt={t('footer.instagram')} style={{ height: 45 }}/>
                </a>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <AppBar
          component="div"
          color="default"
          position="static"
          className={clsx(classes.appBar, classes.bottomAppBar)}
        >
          <Toolbar disableGutters className={classes.toolBar}>
            <div className={classes.bottomMenuItemsContainer}>
              {renderMenuItems(menuItemsBottom)}
            </div>
          </Toolbar>
        </AppBar>
        <div className={classes.footerText}>
          <span>{t('footer.acknowledge')}</span>
          <span>{`© ${config.year} SBS`}</span>
        </div>
      </OdContainer>
    </footer>
  );
};

const FooterWithRtlProvider = () => {
  return <RtlThemeProvider><Footer/></RtlThemeProvider>;
};

export default FooterWithRtlProvider;
