import { Location } from 'history';
import * as ls from 'local-storage';
import { fill as _fill, findIndex, get, pick, slice } from 'lodash';

import OnDemand from '@@types/OnDemand';
import Logger from '@@utils/logger/Logger';

import i18n from '../i18n';
import breakpoints from '../styles/breakpoints';
import padding from '../styles/padding';

// eslint-disable-next-line import/prefer-default-export
export function insertArrayAtIndex(arrA, arrB, index, fill = null) {
  let _resultArr = [];
  if (arrA.length < index) {
    _resultArr = arrA.concat(fill(Array(index - arrA.length), _fill));
  } else {
    _resultArr = arrA.slice(0, index);
  }

  return _resultArr
    .concat(arrB)
    .concat(arrA.slice(index + arrB.length, arrA.length));
}

export function nl2br(text) {
  return text ? text.split('\n').map((item, key) => {
    return (
      // eslint-disable-next-line react/no-array-index-key
      <span key={key}>
        {item}
        <br/>
      </span>
    );
  }) : '';
}

export function escapeRegExp(string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function queryString(params): string {
  return Object.keys(params).map((key) => {
    return `${key}=${encodeURIComponent(params[key])}`;
  }).join('&');
}

/**
 * Button key listener will action the callback on enter / space key
 * @param callback
 */
export function onButtonKeyListener(callback) {
  return (e) => {
    if (e.detail === 0 // check that this is a keyboard event
      && e.currentTarget === e.target // only trigger the callback if it's not a bubbled event
      && (e.key === 'Enter' || e.key === ' ')) {
      // pressing space would scroll the page, so we need to prevent it bubbling up
      e.preventDefault();
      callback(e);
    }
  };
}

/**
 * Link key listener will action the callback on enter key
 * @param callback
 */
export function onLinkKeyListener(callback) {
  return (e) => {
    if (e.detail === 0 // check that this is a keyboard event
      && e.currentTarget === e.target // only trigger the callback if it's not a bubbled event
      && e.key === 'Enter') {
      callback(e);
    }
  };
}

/**
 * @deprecated - use tileItemsPerView16x9 when v1 shelf is removed
 */
export const tileItemsPerViewLandscape = {
  xl: 6,
  lg: 6,
  md: 5,
  sm: 3,
  xs: 2,
};

/**
 * @deprecated - use tileItemsPerView2x3 when v1 shelf is removed
 */
export const tileItemsPerViewPortrait = {
  xl: 8,
  lg: 8,
  md: 7,
  sm: 4,
  xs: 2,
};

export const tileItemsPerView16x9 = {
  xl: 6,
  lg: 6,
  md: 5,
  sm: 3,
  xs: 2,
};

export const tileItemsPerView2x3 = {
  xl: 8,
  lg: 8,
  md: 7,
  sm: 4,
  xs: 2,
};

export const tileItemsPerViewTall = {
  xl: 7,
  lg: 7,
  md: 6,
  sm: 4,
  xs: 2,
};

export const tileItemsPerViewSquare = {
  xl: 8,
  lg: 7,
  md: 6,
  sm: 4,
  xs: 2,
};

export interface TileSpec {
  width: number;
  height: number;
  imageHeight: number;
  itemsPerView: number;
  orientation: 'square' | 'tall' | 'portrait' | 'landscape';
}

export function getTileSpec(itemType, windowWidth, breakpoint): TileSpec {
  let itemsPerViewSettings;
  let aspectRatio;
  let orientation;

  switch (itemType) {
    case 'collection':
      itemsPerViewSettings = tileItemsPerViewTall;
      aspectRatio = 2 / 3;
      orientation = 'tall';
      break;

    case 'circle':
      itemsPerViewSettings = tileItemsPerViewSquare;
      aspectRatio = 1;
      orientation = 'square';
      break;

    case 'movie':
      itemsPerViewSettings = tileItemsPerView2x3;
      aspectRatio = 2 / 3;
      orientation = 'portrait';
      break;

    default:
      itemsPerViewSettings = tileItemsPerView16x9;
      aspectRatio = 16 / 9;
      orientation = 'landscape';
      break;
  }

  const itemsPerView = itemsPerViewSettings[breakpoint];

  /* Dynamic height calculation:
          height = (100vw - offset) / noOfItems / aspectRatio + metadataHeight
          offset = 2*sidePadding + noOfItems * gutterWidth + scrollbar width
  */
  const htmlFontSize = 16; // currently hardcoded because we can't get this from the default theme options
  const sidePadding = padding.container[breakpoint];
  const scrollbarWidth = 0; // there is no easy way to get scrollbar width, so we'll leave it at 0
  const offset = 2 * sidePadding + itemsPerView * 8 + scrollbarWidth;
  // metadataHeight = 2 x box padding + padding between two lines + approx height of the metadata
  const metadataHeight = 20 + 4 + 2.5 * htmlFontSize;
  const width = (windowWidth - offset) / itemsPerView;
  const imageHeight = width / aspectRatio;
  const height = imageHeight + (itemType === 'collection' ? 0 : metadataHeight);

  return {
    width,
    height,
    imageHeight,
    itemsPerView,
    orientation,
  };
}

export interface TileSpecV2 extends TileSpec {
  growRatio: number;
}

/**
 * Get the ratio by which the tile and its child elements grows when activated
 * @param aspectRatio
 * @returns tileGrowRatio
 */
export function getTileGrowRatio(aspectRatio) {
  if (aspectRatio === 2 / 3) {
    return 1.4;
  }

  return 1.8;
}

/**
 * Used for determining how many tiles are displayed per breakpoint and grow ratio
 * @param itemType
 * @param windowWidth
 * @param breakpoint
 * @returns
 */
export function getTileSpecV2(itemType: '2:3' | '16:9' | 'list', windowWidth: number, breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl'): TileSpecV2 {
  let itemsPerViewSettings;
  let aspectRatio;
  let orientation;

  switch (itemType) {
    case '2:3':
      itemsPerViewSettings = tileItemsPerView2x3;
      aspectRatio = 2 / 3;
      orientation = 'portrait';
      break;

    case '16:9':
    default:
      itemsPerViewSettings = tileItemsPerView16x9;
      aspectRatio = 16 / 9;
      orientation = 'landscape';
      break;
  }

  const itemsPerView = itemsPerViewSettings[breakpoint];

  const htmlFontSize = 16; // currently hardcoded because we can't get this from the default theme options
  const sidePadding = padding.container[breakpoint];
  const scrollbarWidth = 0; // there is no easy way to get scrollbar width, so we'll leave it at 0
  const offset = 2 * sidePadding + itemsPerView * 8 + scrollbarWidth;
  // metadataHeight = 2 x box padding + padding between two lines + approx height of the metadata
  const metadataHeight = 20 + 4 + 2.5 * htmlFontSize;
  const width = (windowWidth - offset) / itemsPerView;
  const imageHeight = width / aspectRatio;
  const height = imageHeight + metadataHeight;

  return {
    width,
    height,
    imageHeight,
    itemsPerView,
    orientation,
    growRatio: getTileGrowRatio(aspectRatio),
  };
}

/**
 * Regex to strip ansi colours
 * @param onlyFirst
 */
export function ansiRegex({ onlyFirst = false } = {}) {
  const pattern = [
    '[\\u001B\\u009B][[\\]()#;?]*(?:(?:(?:(?:;[-a-zA-Z\\d\\/#&.:=?%@~_]+)*|[a-zA-Z\\d]+(?:;[-a-zA-Z\\d\\/#&.:=?%@~_]*)*)?\\u0007)',
    '(?:(?:\\d{1,4}(?:;\\d{0,4})*)?[\\dA-PR-TZcf-ntqry=><~]))',
  ].join('|');

  return new RegExp(pattern, onlyFirst ? undefined : 'g');
}

/**
 * Strip ansi colours
 * @param string
 */
export function stripAnsi(string: string) {
  if (typeof string !== 'string') {
    throw new TypeError(`Expected a \`string\`, got \`${typeof string}\``);
  }

  return string.replace(ansiRegex(), '');
}

export function hash(text: string): string {
  let hashValue = 0;
  let i;
  let chr;

  if (text.length === 0) return hashValue.toString(10);
  for (i = 0; i < text.length; i += 1) {
    chr = text.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hashValue = ((hashValue << 5) - hashValue) + chr;
    // eslint-disable-next-line no-bitwise
    hashValue |= 0; // Convert to 32bit integer
  }
  return Math.abs(hashValue).toString(10);
}

export function mapSizesToProps({ width }) {
  let breakpoint = 'lg';

  Object.entries(breakpoints).forEach(([key, value]) => {
    if (width && value <= width) {
      breakpoint = key;
    }
  });

  return {
    windowWidth: width,
    breakpoint,
  };
}

export function getTypeByGenres(genres: string[]): OnDemand.SeriesType {
  let seriesType: OnDemand.SeriesType = 'tv';

  if (findIndex(genres, (genre) => {
    return genre.toLowerCase().indexOf('news') !== -1 || genre.toLowerCase().indexOf('news and current affairs') !== -1;
  }) !== -1) {
    seriesType = 'news';
  } else if (findIndex(genres, (genre) => {
    return genre.toLowerCase().indexOf('sport') !== -1 || genre.toLowerCase().indexOf('sports') !== -1;
  }) !== -1) {
    seriesType = 'sports';
  }

  return seriesType;
}

export const updatePlayerControlsVisibilityClass = (shown: boolean, playerId: string) => {
  const playerContainer = document.getElementById(`video-player-${playerId}`);
  if (shown === true) {
    playerContainer.classList.add('player-controls-visible');
  } else {
    playerContainer.classList.remove('player-controls-visible');
  }
};

type ScrollPosition = { x: number; y: number };

/**
 * Save location scroll position to session storage so that we can restore it later
 * @param location
 * @param scrollPosition
 */
export function saveLocationScrollPosition(location: Location, scrollPosition: ScrollPosition) {
  ls.backend(sessionStorage);

  let locationKey = location.key;
  // on the first render, the key is null (History V4) or default (History V5)
  if (!locationKey || locationKey === 'default') {
    // in that case, we'll generate a key based on the whole location object
    locationKey = hash(JSON.stringify(location));
  }

  let scrollHistory = ls.get<Record<string, ScrollPosition>>('scrollHistory') || {};
  let scrollHistoryKeys = ls.get<string[]>('scrollHistoryKeys') || [];

  if (typeof scrollHistory !== 'object' || Array.isArray(scrollHistory)) {
    scrollHistory = {};
  }

  if (!Array.isArray(scrollHistoryKeys)) {
    scrollHistoryKeys = [];
  }

  // clean up excessive history every 100 items, keep 80
  if (scrollHistoryKeys.length >= 100) {
    scrollHistoryKeys = slice(scrollHistoryKeys, -80);
    scrollHistory = pick(scrollHistory, scrollHistoryKeys);
  }

  // set the scroll history
  ls.set('scrollHistoryKeys', scrollHistoryKeys.concat([locationKey]));
  ls.set('scrollHistory', {
    ...scrollHistory,
    [locationKey]: scrollPosition,
  });
}

/**
 * Restore scroll position for a locationKey
 * @param location
 * @param timeout
 */
export function restoreLocationScrollPosition(location: Location, timeout = undefined) {
  ls.backend(sessionStorage);
  const scrollHistory = ls.get<Record<string, ScrollPosition>>('scrollHistory') || {};

  let locationKey = location.key;
  if (!locationKey || locationKey === 'default') {
    locationKey = hash(JSON.stringify(location));
  }

  const scrollPosition = get(scrollHistory, locationKey);

  if (scrollPosition) {
    if (typeof timeout !== 'undefined') {
      setTimeout(() => {
        window.scrollTo(scrollPosition.x, scrollPosition.y);
      }, timeout);
    } else {
      window.scrollTo(scrollPosition.x, scrollPosition.y);
    }
  }
}

export function getScrollPosition(): ScrollPosition {
  const documentElement = document.documentElement || document.body.parentNode as HTMLElement || document.body;
  const x = window.scrollX || window.pageXOffset || documentElement.scrollLeft;
  const y = window.scrollY || window.pageYOffset || documentElement.scrollTop;

  return { x, y };
}

export function loadScript(src: string): Promise<Event> {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.async = true;
    script.src = src;
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });
}

export function getNextEpisodeInSeries(mpxId: string, series: OnDemand.TvSeries): OnDemand.SlimEpisode {
  let found = false;
  let nextEpisode: OnDemand.SlimEpisode;

  series.seasons.forEach((season) => {
    season.episodes.forEach((episode) => {
      if (found && !nextEpisode) {
        nextEpisode = episode;
      }

      if (episode.id === mpxId) {
        found = true;
      }
    });
  });

  return nextEpisode;
}

/**
 * Convert uuid to a number between 0-99 so that we can use them as a base of control testing
 * @param uuid
 */
export function getUUIDMod(uuid) {
  const hex = uuid.replace(/-/g, '');
  const low = hex.substring(16);
  const lowInt = BigInt(`0x${low}`);
  return Number(lowInt % BigInt(100));
}

/**
 * Check if the entityType and the user is allowed to use the new recommendation
 * This function can only be called on the client side
 */
export function canUseSmartRecommendation(entityType?: string): boolean {
  const blacklist = ['NEWS_SERIES', 'SPORTS_SERIES', 'NEWS_PROGRAM', 'SPORTS_PROGRAM', 'NEWS_EPISODE', 'SPORTS_EPISODE'];
  return !blacklist?.includes(entityType);
}

/**
 * Transform the auth api host to sbs.com.au or sbsod.com depending on hostname
 */
export function transformAuthApiHost(hostname: string) {
  let transformedHostname = hostname;

  if (window.location.hostname.match(/sbsod.com$/)) {
    if (transformedHostname.match(/(dev|qa|stg)\.sbs.com.au$/)) {
      transformedHostname = hostname.replace('sbs.com.au', 'sbsod.com');
    } else {
      transformedHostname = hostname.replace('sbs.com.au', 'pr.sbsod.com');
    }
  }
  if (window.location.hostname.match(/sbs.com.au$/)) {
    transformedHostname = hostname.replace(/(pr\.)?sbsod.com$/, 'sbs.com.au');
  }
  return transformedHostname;
}

export function logVideoNotMatching(id, video: OnDemand.Video, extraLogs = {}) {
  if (video && id !== video.id) {
    const logObject = {
      requestedVideoId: id,
      serverSideRendered: typeof window === 'undefined',
      video,
      ...extraLogs,
    };

    Logger.error('Video not matching the response', logObject);
  }
}
