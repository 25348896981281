import { AxiosResponse } from 'axios';

import { components } from '@@types/UserActivity';
import { logHttpError, userActivityHttpClient } from '@@utils/HttpClient';
import {
  USER_ACTIVITY_CONTINUE_WATCHING_PATH,
  USER_ACTIVITY_CONTINUE_WATCHING_ENTITY_PATH,
  USER_ACTIVITY_CONTINUE_WATCHING_ALL_PATH,
  USER_ACTIVITY_FAVOURITE_PATH,
  USER_ACTIVITY_FAVOURITE_ENTITY_PATH,
  USER_ACTIVITY_FAVOURITE_TYPE_PATH,
  USER_ACTIVITY_PROGRESS_PATH,
  USER_ACTIVITY_PROGRESS_ENTITY_PATH,
} from '@@utils/constants';

export type ProgressAll = Array<{
  seconds: number
  entityID: string
  updatedAt: string
}>;

export class UserActivityInternalServerError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'UserActivityInternalServerError';
  }
}

export class UserActivityBadRequestError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'UserActivityBadRequestError';
  }
}

export class UserActivityUnauthorisedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'UserActivityUnauthorisedError';
  }
}

/**
 * To remove item(s) from continue watching list for user logged in.
 */
export async function deleteContinueWatchingItems(items: string) {
  const httpClient = userActivityHttpClient();
  try {
    const { data } = await httpClient.delete<components['schemas']['models.NoBody'], AxiosResponse<components['schemas']['models.NoBody']>>(USER_ACTIVITY_CONTINUE_WATCHING_ENTITY_PATH.replace(':entity', items));
    return data;
  } catch (e) {
    if (e.response.status === 401) {
      const error = new UserActivityUnauthorisedError('Unauthorized');
      logHttpError(e, error, 'warn');
      throw error;
    }
    if (e.response.status === 500) {
      const error = new UserActivityInternalServerError('Internal server error');
      logHttpError(e, error, 'warn');
      throw error;
    }
    const error = new Error('Unhandled UserActivity error');
    logHttpError(e, error, 'error');
    throw error;
  }
}

/**
 * To remove all items in continue watching list.
 */
export async function deleteContinueWatchingAll() {
  const httpClient = userActivityHttpClient();
  try {
    const { data } = await httpClient.delete<components['schemas']['models.NoBody'], AxiosResponse<components['schemas']['models.NoBody']>>(USER_ACTIVITY_CONTINUE_WATCHING_ALL_PATH);
    return data;
  } catch (e) {
    if (e.response.status === 401) {
      const error = new UserActivityUnauthorisedError('Unauthorized');
      logHttpError(e, error, 'warn');
      throw error;
    }
    if (e.response.status === 500) {
      const error = new UserActivityInternalServerError('Internal server error');
      logHttpError(e, error, 'warn');
      throw error;
    }
    const error = new Error('Unhandled UserActivity error');
    logHttpError(e, error, 'error');
    throw error;
  }
}

/**
 * Get continue watching items.
 */
export async function getContinueWatching() {
  const httpClient = userActivityHttpClient();
  try {
    const { data } = await httpClient.get<components['schemas']['models.ContinueWatchingItem'][], AxiosResponse<components['schemas']['models.ContinueWatchingItem'][]>>(USER_ACTIVITY_CONTINUE_WATCHING_PATH);
    return data;
  } catch (e) {
    if (e.response.status === 401) {
      const error = new UserActivityUnauthorisedError('Unauthorized');
      logHttpError(e, error, 'warn');
      throw error;
    }
    const error = new Error('Unhandled UserActivity error');
    logHttpError(e, error, 'error');
    throw error;
  }
}

/**
 * To retrieve favourite records of user logged in.
 */
export async function getFavourite() {
  const httpClient = userActivityHttpClient();
  try {
    const { data } = await httpClient.get<components['schemas']['models.FavouriteResponse'][], AxiosResponse<components['schemas']['models.FavouriteResponse'][]>>(USER_ACTIVITY_FAVOURITE_PATH);
    return data;
  } catch (e) {
    if (e.response.status === 401) {
      const error = new UserActivityUnauthorisedError('Unauthorized');
      logHttpError(e, error, 'warn');
      throw error;
    }
    if (e.response.status === 500) {
      const error = new UserActivityInternalServerError('Internal server error');
      logHttpError(e, error, 'warn');
      throw error;
    }
    const error = new Error('Unhandled UserActivity error');
    logHttpError(e, error, 'error');
    throw error;
  }
}

/**
 * To add favourite record(s) for user logged in.
 * @param favourites
 */
export async function postFavourite(favourites: components['schemas']['models.Favourite'][]) {
  const httpClient = userActivityHttpClient();
  try {
    const { data } = await httpClient.post<components['schemas']['models.NoBody'], AxiosResponse<components['schemas']['models.NoBody']>>(USER_ACTIVITY_FAVOURITE_PATH, favourites);
    return data;
  } catch (e) {
    if (e.response.status === 400) {
      const error = new UserActivityBadRequestError('Invalid request');
      logHttpError(e, error, 'warn');
      throw error;
    }
    if (e.response.status === 401) {
      const error = new UserActivityUnauthorisedError('Unauthorized');
      logHttpError(e, error, 'warn');
      throw error;
    }
    if (e.response.status === 500) {
      const error = new UserActivityInternalServerError('Internal server error');
      logHttpError(e, error, 'warn');
      throw error;
    }
    const error = new Error('Unhandled UserActivity error');
    logHttpError(e, error, 'error');
    throw error;
  }
}

/**
 * To delete favourite record(s) for user logged in.
 * @param entity
 */
export async function deleteFavouriteByEntity(entity: string) {
  const httpClient = userActivityHttpClient();
  try {
    await httpClient.delete<never, AxiosResponse<never>>(USER_ACTIVITY_FAVOURITE_ENTITY_PATH.replace(':entity', entity));
  } catch (e) {
    if (e.response.status === 401) {
      const error = new UserActivityUnauthorisedError('Unauthorized');
      logHttpError(e, error, 'warn');
      throw error;
    }
    if (e.response.status === 500) {
      const error = new UserActivityInternalServerError('Internal server error');
      logHttpError(e, error, 'warn');
      throw error;
    }
    const error = new Error('Unhandled UserActivity error');
    logHttpError(e, error, 'error');
    throw error;
  }
}

/**
 * To retrieve favourite records with certain type(s) of user logged in.
 * @param types
 */
export async function getFavouriteByType(types: string) {
  const httpClient = userActivityHttpClient();
  try {
    const { data } = await httpClient.get<components['schemas']['models.FavouriteResponse'][], AxiosResponse<components['schemas']['models.FavouriteResponse'][]>>(USER_ACTIVITY_FAVOURITE_TYPE_PATH.replace(':types', types));
    return data;
  } catch (e) {
    if (e.response.status === 401) {
      const error = new UserActivityUnauthorisedError('Unauthorized');
      logHttpError(e, error, 'warn');
      throw error;
    }
    if (e.response.status === 500) {
      const error = new UserActivityInternalServerError('Internal server error');
      logHttpError(e, error, 'warn');
      throw error;
    }
    const error = new Error('Unhandled UserActivity error');
    logHttpError(e, error, 'error');
    throw error;
  }
}

/**
 * To save progress.
 * @param progress
 */
export async function postProgress(progress: components['schemas']['models.Progress']) {
  const httpClient = userActivityHttpClient();
  try {
    const { data } = await httpClient.post<components['schemas']['models.NoBody'], AxiosResponse<components['schemas']['models.NoBody']>>(USER_ACTIVITY_PROGRESS_PATH, progress);
    return data;
  } catch (e) {
    if (e.response.status === 400) {
      const error = new UserActivityBadRequestError('Invalid request');
      logHttpError(e, error, 'warn');
      throw error;
    }
    if (e.response.status === 401) {
      const error = new UserActivityUnauthorisedError('Unauthorized');
      logHttpError(e, error, 'warn');
      throw error;
    }
    if (e.response.status === 500) {
      const error = new UserActivityInternalServerError('Internal server error');
      logHttpError(e, error, 'warn');
      throw error;
    }
    const error = new Error('Unhandled UserActivity error');
    logHttpError(e, error, 'error');
    throw error;
  }
}

/**
 * To retrieve all progress records.
 */
export async function getProgress() {
  const httpClient = userActivityHttpClient();
  try {
    const { data } = await httpClient.get<components['schemas']['models.ProgressResponse'][], AxiosResponse<components['schemas']['models.ProgressResponse'][]>>(USER_ACTIVITY_PROGRESS_PATH);
    return data;
  } catch (e) {
    const error = new Error('Unhandled UserActivity error');
    logHttpError(e, error, 'error');
    throw error;
  }
}

/**
 * To retrieve progress records of ID(s) provided.
 * @param entity
 */
export async function getProgressByEntity(entity: string) {
  const httpClient = userActivityHttpClient();
  try {
    const { data } = await httpClient.get<components['schemas']['models.ProgressResponse'][], AxiosResponse<components['schemas']['models.ProgressResponse'][]>>(USER_ACTIVITY_PROGRESS_ENTITY_PATH.replace(':entity', entity));
    return data;
  } catch (e) {
    if (e.response.status === 500) {
      const error = new UserActivityInternalServerError('Internal server error');
      logHttpError(e, error, 'warn');
      throw error;
    }
    const error = new Error('Unhandled UserActivity error');
    logHttpError(e, error, 'error');
    throw error;
  }
}

/**
 * To delete progress.
 * @param entity
 */
export async function deleteProgressByEntity(entity: string) {
  const httpClient = userActivityHttpClient();
  try {
    await httpClient.delete<never, AxiosResponse<never>>(USER_ACTIVITY_PROGRESS_ENTITY_PATH.replace(':entity', entity));
  } catch (e) {
    if (e.response.status === 400) {
      const error = new UserActivityBadRequestError('Invalid request');
      logHttpError(e, error, 'warn');
      throw error;
    }
    if (e.response.status === 401) {
      const error = new UserActivityUnauthorisedError('Unauthorized');
      logHttpError(e, error, 'warn');
      throw error;
    }
    if (e.response.status === 500) {
      const error = new UserActivityInternalServerError('Internal server error');
      logHttpError(e, error, 'warn');
      throw error;
    }
    const error = new Error('Unhandled UserActivity error');
    logHttpError(e, error, 'error');
    throw error;
  }
}

export default {
  UserActivityInternalServerError,
  UserActivityBadRequestError,
  UserActivityUnauthorisedError,
  deleteContinueWatchingItems,
  deleteContinueWatchingAll,
  getContinueWatching,
  getFavourite,
  postFavourite,
  deleteFavouriteByEntity,
  getFavouriteByType,
  postProgress,
  getProgress,
  getProgressByEntity,
  deleteProgressByEntity,
};
