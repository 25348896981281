import { createSlice, createSelector } from '@reduxjs/toolkit';

import { V3_API_HOST } from '@@utils/constants';

import Config from '../@types/Config';
import type { RootState } from '../store';

const initialState: Config.Config = {
  movieProduct: {
    rows: [{
      name: 'More Like This',
      type: 'shelf',
      itemType: 'movie',
      feedUrl: `${V3_API_HOST}/api/v3/video_recommendations/moremovies?id=[VIDEOID]&context=odwebsite`,
      requireLogin: false,
    }],
  },
  episodeProduct: {
    rows: [{
      name: 'More Like This',
      type: 'shelf',
      itemType: 'title-under',
      feedUrl: `${V3_API_HOST}/api/v3/video_recommendations/moreseries?type=episode&id=[VIDEOID]&context=odwebsite`,
      requireLogin: false,
    }],
  },
  oneOffProduct: {
    rows: [{
      name: 'More Like This',
      type: 'shelf',
      itemType: 'title-under',
      feedUrl: `${V3_API_HOST}/api/v3/video_recommendations/moreseries?type=video&id=[VIDEOID]&context=odwebsite`,
      requireLogin: false,
    }],
  },
};

const configStore = createSlice({
  name: 'ConfigStore',
  initialState,
  reducers: {},
});

export const getConfig = createSelector(
  (state: RootState) => {
    return state.config;
  },
  (config) => {
    return config;
  },
);

export default configStore;
