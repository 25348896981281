import { decode } from 'js-base64';
import { get } from 'lodash';
import { DateTime } from 'luxon';

declare global {
  interface Window {
    _CONFIG_: any;
  }
}

let windowConfig = null;
if (typeof window !== 'undefined' && window._CONFIG_) {
  windowConfig = JSON.parse(decode(window._CONFIG_));
}

// Feature flags
enum FeatureList {
  'adBadge',
  'collectionFilter',
  'surveyBanner',
}

type FeatureName = keyof typeof FeatureList;

function getFeatures(): Record<FeatureName, string> {
  return windowConfig ? windowConfig.features : {
    adBadge: process.env.FEATURE_AD_BADGE,
    collectionFilter: process.env.FEATURE_COLLECTION_FILTER,
    surveyBanner: process.env.FEATURE_SURVEY_BANNER,
  };
}

const config = windowConfig !== null ? {
  // client
  year: windowConfig.year,
} : {
  // server
  features: getFeatures(),
  year: DateTime.now().toFormat('yyyy'),
};

/**
 * The config above is static and will not change for the life of the server app until it's restarted.
 * Call this function to update the config.
 * NOTE: This should only be called once on the server entry point.
 */
export function updateDynamicServerConfig() {
  config.year = DateTime.now().toFormat('yyyy');
}

export const hasFeature = (featureName: FeatureName): boolean => {
  const features = getFeatures();
  const feature = get(features, featureName, 'false');

  if (feature && feature !== 'false') {
    let startDate;
    let endDate;

    // Check for time restriction
    if (feature.includes('|')) {
      const dateMatches = feature.match(/(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:[+-][0-2]\d:[0-5]\d|Z))\|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:[+-][0-2]\d:[0-5]\d|Z))/);

      if (dateMatches) {
        [, startDate, endDate] = dateMatches;
        const now = DateTime.local();

        if (startDate) {
          startDate = DateTime.fromISO(startDate);

          // start date not reached yet
          if (now < startDate) {
            return false;
          }
        }

        if (endDate) {
          endDate = DateTime.fromISO(endDate);

          // has expired
          if (now >= endDate) {
            return false;
          }
        }
      }
    }

    return true;
  }

  return false;
};

export default config;
