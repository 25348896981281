// we can't use object destructuring here because the process.env[KEY] will be replaced during build
/* eslint-disable prefer-destructuring */

export const PROXY = process.env.PROXY;

export const VIDEO_CONFIG_PATH = '/api/v3/video_config';
export const SEARCH_PATH = '/catalogue';
export const VIDEO_STREAM_PATH = '/api/v3/video_stream';
export const VIDEO_RELATED_PATH = '/api/v3/video_recommendations/relatedvideos';
export const PROGRAM_LOOKUP_PATH = '/api/v3/video_program?slug=[SLUG]';
export const PROGRAM_LOOKUP_BY_ID_PATH = '/api/v3/video_program?id=[ID]';
export const VIDEO_LOOKUP_PATH = '/api/v3/video_detail?id=[VIDEOID]';
export const PROGRESS_RECORD_PATH = '/api/v3/video_progress/record';

// User profile
export const NEWSLETTER_SUBSCRIPTIONS_PATH = '/newsletters';

export const VERSION = process.env.VERSION;
export const BUILD_HASH = process.env.BUILD_HASH;

export const CATALOGUE_MEDIA_BY_MPX_ID = '/mpx-media/{mpxMediaID}';
export const CATALOGUE_TV_SERIES_BY_SLUG = '/tv-series/{slug}';
export const CATALOGUE_NEWS_SERIES_BY_SLUG = '/news-series/{slug}';
export const CATALOGUE_SPORTS_SERIES_BY_SLUG = '/sports-series/{slug}';
export const CATALOGUE_PAGE_BY_SLUG = '/pages/{slug}';
export const COLLECTION_BY_SLUG = '/collections/{slug}';
export const CATALOGUE_DOCUMENTS = '/documents/{name}';
export const PLAYBACK_STREAM_BY_MPX_ID = '/stream/{id}';
export const CATALOGUE_PERSON_BY_SLUG = '/persons/{slug}';
export const CATALOGUE_FAVOURITES_LIST = '/favourite';

export const ADNXS_API_HOST = 'https://ib.adnxs.com';
export const ADNXS_API_HEADER_BIDDING_PATH = '/prebid/lfv';

// shared build time variables
export const IMAGE_HOST = process.env.BVAR_IMAGE_HOST;
export const IMAGE_RESIZER_URL = process.env.BVAR_IMAGE_RESIZER_URL;
export const V3_API_HOST = process.env.BVAR_V3_API_HOST;
export const MYACCOUNT_HOST = process.env.BVAR_MYACCOUNT_HOST;
export const CHROMECAST_RCV_APP_ID = process.env.BVAR_CHROMECAST_RCV_APP_ID;
export const CHROMECAST_RCV_APP_V3_ID = process.env.BVAR_CHROMECAST_RCV_APP_V3_ID;
export const BASENAME = process.env.BASENAME;
export const WEB_HOST = typeof window !== 'undefined' ? `${document.location.protocol}//${document.location.host}` : process.env.BVAR_WEB_HOST;
export const CATALOGUE_API_KEY = process.env.BVAR_CATALOGUE_API_KEY || '';
export const BITMOVIN_LICENSE_KEY = process.env.BVAR_BITMOVIN_LICENSE_KEY || '';
export const BITMOVIN_ANALYTICS_LICENSE_KEY = process.env.BVAR_BITMOVIN_ANALYTICS_LICENSE_KEY || '';

// No dates set would always enable the promo if URL is provided
const rewindPromoDates = process.env.BVAR_REWIND_PROMO_DATES || '';
const [rewindStartDate, rewindEndDate] = rewindPromoDates.split('|');

interface RewindPromoConfig {
  startDate: string;
  endDate: string;
  url: string;
}
export const REWIND_PROMO: RewindPromoConfig = {
  startDate: rewindStartDate,
  endDate: rewindEndDate,
  url: process.env.BVAR_REWIND_URL || '',
};

export const USER_ACTIVITY_CONTINUE_WATCHING_PATH = '/continue-watching';
export const USER_ACTIVITY_CONTINUE_WATCHING_ENTITY_PATH = '/continue-watching/:entity';
export const USER_ACTIVITY_CONTINUE_WATCHING_ALL_PATH = '/continue-watching/all';
export const USER_ACTIVITY_FAVOURITE_PATH = '/favourite';
export const USER_ACTIVITY_FAVOURITE_ENTITY_PATH = '/favourite/:entity';
export const USER_ACTIVITY_FAVOURITE_TYPE_PATH = '/favourite/:types';
export const USER_ACTIVITY_PROGRESS_PATH = '/progress';
export const USER_ACTIVITY_PROGRESS_ENTITY_PATH = '/progress/:entity';
