import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import UserActivityApi from '@@src/apis/UserActivity';
import { RootState } from '@@src/store';

type Entity = {
  entityId?: string,
  seconds?: number,
  percent?: number,
  updatedAt?: string
};

type ProgressMap = { [key: string]: Omit<Entity, 'entityId'> };

export interface UserActivityStoreState {
  progress: ProgressMap;
  loading: boolean;
}

const initialState: UserActivityStoreState = {
  progress: {},
  loading: false,
};

interface Args {
  entityId: string;
}

interface ThunkConfig {
  state: RootState
  rejectValue: string // reject entityId to add/remove from store on failed requests
}

function mapEntitiesToObject(entities: Entity[]): ProgressMap {
  return entities.reduce((acc, entity) => {
    return {
      ...acc,
      [entity.entityId]: {
        seconds: entity.seconds,
        percent: entity.percent,
        updatedAt: entity.updatedAt,
      },
    };
  }, {} as ProgressMap); // Properly type the accumulator
}

export const getProgressAsyncThunk = createAsyncThunk<Entity[], undefined, ThunkConfig>('userActivity/getProgressAll', async (args, thunkConfig) => {
  try {
    const response = await UserActivityApi.getProgress();
    return response.map((i) => {
      return {
        entityId: i.entityID,
        percent: i['percent'],
        seconds: i.seconds,
        updatedAt: i.updatedAt,
      };
    });
  } catch (e) {
    return thunkConfig.rejectWithValue('');
  }
});

export const getProgressByEntityAsyncThunk = createAsyncThunk<Entity[], Args, ThunkConfig>('userActivity/getProgressByEntity', async (args, thunkConfig) => {
  try {
    const response = await UserActivityApi.getProgressByEntity(args.entityId);
    return response.map((i) => {
      return {
        entityId: i.entityID,
        percent: i['percent'],
        seconds: i.seconds,
        updatedAt: i.updatedAt,
      };
    });
  } catch (e) {
    return thunkConfig.rejectWithValue(args.entityId);
  }
});

export const postProgressAsyncThunk = createAsyncThunk<Entity, Entity, ThunkConfig>('userActivity/postProgress', async (args, thunkConfig) => {
  try {
    await UserActivityApi.postProgress({
      entityID: args.entityId,
      seconds: args.seconds,
    });
    return {
      ...args,
    };
  } catch (e) {
    return thunkConfig.rejectWithValue(args.entityId);
  }
});

const userActivityStore = createSlice({
  name: 'UserActivityStore',
  initialState,
  reducers: {
    setProgress: (state, action: PayloadAction<Entity>) => {
      state.progress = {
        ...state.progress,
        [action.payload.entityId]: action.payload,
      };
    },
    clear: (state) => {
      state.progress = { };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProgressByEntityAsyncThunk.pending, (state, _action) => {
      state.loading = true;
    });
    builder.addCase(getProgressByEntityAsyncThunk.fulfilled, (state, action) => {
      state.progress = {
        ...state.progress,
        ...mapEntitiesToObject(action.payload),
      };
      state.loading = false;
    });
    builder.addCase(getProgressByEntityAsyncThunk.rejected, (state, _action) => {
      state.loading = false;
    });

    builder.addCase(getProgressAsyncThunk.pending, (state, _action) => {
      state.loading = true;
    });
    builder.addCase(getProgressAsyncThunk.fulfilled, (state, action) => {
      state.progress = {
        ...state.progress,
        ...mapEntitiesToObject(action.payload),
      };
      state.loading = false;
    });
    builder.addCase(getProgressAsyncThunk.rejected, (state, _action) => {
      state.loading = false;
    });

    builder.addCase(postProgressAsyncThunk.pending, (state, _action) => {
      state.loading = true;
    });
    builder.addCase(postProgressAsyncThunk.fulfilled, (state, action) => {
      const { entityId, seconds, percent } = action.payload;

      state.loading = false;
      state.progress = {
        ...state.progress,
        [entityId]: {
          seconds,
          percent,
        },
      };
    });
    builder.addCase(postProgressAsyncThunk.rejected, (state, _action) => {
      state.loading = false;
    });
  },
});

export default userActivityStore;
export const { setProgress } = userActivityStore.actions;

export const selectProgress = createSelector(
  (rootState: RootState) => {
    return rootState.userActivity;
  },
  (userActivity) => {
    return userActivity.progress;
  },
);
