import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import UserActivity from '@@src/apis/UserActivity';
import { RootState } from '@@src/store';

type Favourite = {
  entityId: string;
  entityType: 'MOVIE' | 'TV_SERIES' | 'NEWS_SERIES' | 'SPORTS_SERIES' | 'TV_PROGRAM' | 'NEWS_PROGRAM' | 'SPORTS_PROGRAM' | 'CURATED_COLLECTION' | 'DYNAMIC_COLLECTION' | 'PAGE' | 'PERSON'
};

type SnackPack = {
  type: string;
  key: string;
};

export interface FavouritesStoreState {
  items: Favourite[]
  snackPack: SnackPack[];
  loading: boolean;
}

const initialState: FavouritesStoreState = {
  items: [],
  snackPack: [],
  loading: false,
};

export const addFavouriteAsyncThunk = createAsyncThunk<Favourite, Favourite, { state: RootState, rejectValue: Favourite }>('favourites/add', async (args, thunkConfig) => {
  try {
    await UserActivity.postFavourite([args]);
    return args;
  } catch (e) {
    return thunkConfig.rejectWithValue(args);
  }
});

export const removeFavouriteAsyncThunk = createAsyncThunk<Favourite, Favourite, { state: RootState, rejectValue: Favourite }>('favourites/remove', async (args, thunkConfig) => {
  try {
    await UserActivity.deleteFavouriteByEntity(args.entityId);
    return args;
  } catch (e) {
    return thunkConfig.rejectWithValue(args);
  }
});

export const listFavouritesAsyncThunk = createAsyncThunk<Favourite[], undefined, { state: RootState, rejectValue: string }>('favourites/list', async (_args, thunkConfig) => {
  try {
    const response = await UserActivity.getFavourite();
    return response.map((favourite) => {
      return {
        entityId: favourite.entityID,
        entityType: favourite.entityType,
      };
    });
  } catch (e) {
    return thunkConfig.rejectWithValue('failed');
  }
});

const favouritesStore = createSlice({
  name: 'FavouritesStore',
  initialState,
  reducers: {
    addSnackBarToPack(state, action: PayloadAction<string>) {
      state.snackPack = [...state.snackPack, { type: action.payload, key: new Date().getTime().toString() }];
    },
    setSnackPack(state, action: PayloadAction<SnackPack[]>) {
      state.snackPack = action.payload;
    },
    clear(state) {
      state.items = [];
      state.snackPack = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listFavouritesAsyncThunk.pending, (state, _action) => {
      state.loading = true;
    });
    builder.addCase(listFavouritesAsyncThunk.fulfilled, (state, action) => {
      state.items = action.payload;
      state.loading = false;
    });
    builder.addCase(listFavouritesAsyncThunk.rejected, (state, _action) => {
      state.loading = false;
    });

    /**
     * add favourite
     */
    builder.addCase(addFavouriteAsyncThunk.pending, (state, action) => {
      state.items = [...state.items, action.meta.arg]; // add item to store while request pending
    });
    builder.addCase(addFavouriteAsyncThunk.fulfilled, (state, _action) => {
      state.snackPack = [...state.snackPack, { type: 'added', key: new Date().getTime().toString() }];
    });
    builder.addCase(addFavouriteAsyncThunk.rejected, (state, action) => {
      state.items = state.items.filter((item) => { // remove item from store when request fails
        return item.entityId !== action.meta.arg.entityId;
      });
    });
    /**
     * remove favourite
     */
    builder.addCase(removeFavouriteAsyncThunk.pending, (state, action) => {
      state.items = state.items.filter((item) => {
        return item.entityId !== action.meta.arg.entityId; // remove item from store while request pending
      });
    });
    builder.addCase(removeFavouriteAsyncThunk.fulfilled, (state, _action) => {
      state.snackPack = [...state.snackPack, { type: 'removed', key: new Date().getTime().toString() }];
    });
    builder.addCase(removeFavouriteAsyncThunk.rejected, (state, action) => {
      state.items = [...state.items, action.meta.arg]; // add item back to store when request fails
    });
  },
});

export default favouritesStore;
export const { setSnackPack, addSnackBarToPack, clear } = favouritesStore.actions;

export const allFavourites = createSelector(
  (rootState: RootState) => {
    return rootState.favourites;
  },
  (favourites) => {
    return favourites.items;
  },
);

export const isInFavourite = createSelector(
  (rootState: RootState) => {
    return rootState.favourites;
  },
  (_favourites, entityId: string) => {
    return entityId;
  },
  (favourites: FavouritesStoreState, entityId: string) => {
    return favourites.items.find((item) => { return item.entityId === entityId; });
  },
);

export const selectFavourites = createSelector(
  (rootState: RootState) => {
    return rootState.favourites;
  },
  (favourites) => {
    return favourites.items;
  },
);

export const selectFavouritesLoading = createSelector(
  (rootState: RootState) => {
    return rootState.favourites;
  },
  (favourites) => {
    return favourites.loading;
  },
);
