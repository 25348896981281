import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import UserActivityApi from '@@src/apis/UserActivity';
import { RootState } from '@@src/store';
import { transformRecommendedItems } from '@@src/transformers/CatalogApiCollectionTransformer';
import { components } from '@@types/CatalogueApi';
import OnDemand2 from '@@types/OnDemand2';
import { catalogueHttpClientWithAuth } from '@@utils/HttpClient';

export interface UserActivityStoreState {
  items: OnDemand2.CollectionItem[]
  loading: boolean;
}

const initialState: UserActivityStoreState = {
  items: [],
  loading: false,
};

interface ThunkConfig {
  state: RootState
  rejectValue: string
}

type ContinueWatchingCatalogResponse = Array<
| components['schemas']['collectionItemMovie']
| components['schemas']['collectionItemEpisode']
| components['schemas']['collectionItemProgram']
>;

export const getContinueWatchingItemsAsyncThunk = createAsyncThunk<ContinueWatchingCatalogResponse, { url: string }, ThunkConfig>('continueWatching/getContinueWatchingItems', async (args, thunkConfig) => {
  try {
    const httpClient = catalogueHttpClientWithAuth('');
    const response = await httpClient.get<ContinueWatchingCatalogResponse>(args.url);
    return response.data;
  } catch (e) {
    return thunkConfig.rejectWithValue(args.url);
  }
});

export const deleteContinueWatchingItemsAsyncThunk = createAsyncThunk<string, { entityId: string }, ThunkConfig>('continueWatching/postBlacklistItems', async (args, thunkConfig) => {
  try {
    await UserActivityApi.deleteContinueWatchingItems(args.entityId);
    return args.entityId;
  } catch (e) {
    return thunkConfig.rejectWithValue(args.entityId);
  }
});

const continueWatchingSlice = createSlice({
  name: 'UserActivityStore',
  initialState,
  reducers: {
    setItems: (state, action: PayloadAction<{ items: [] }>) => {
      state.items = action.payload.items;
    },
    clear: (state) => {
      state.items = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteContinueWatchingItemsAsyncThunk.pending, (state, action) => {
      state.loading = true;
      state.items = state.items.filter((item) => {
        return item.id !== action.meta.arg.entityId;
      });
    });
    builder.addCase(deleteContinueWatchingItemsAsyncThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.items = state.items.filter((item) => {
        return item.id !== action.payload;
      });
    });
    builder.addCase(deleteContinueWatchingItemsAsyncThunk.rejected, (state, _action) => {
      state.loading = false;
    });

    builder.addCase(getContinueWatchingItemsAsyncThunk.pending, (state, _action) => {
      state.loading = true;
    });
    builder.addCase(getContinueWatchingItemsAsyncThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.items = transformRecommendedItems(action.payload, '16:9');
    });
    builder.addCase(getContinueWatchingItemsAsyncThunk.rejected, (state, _action) => {
      state.loading = false;
    });
  },
});

export default continueWatchingSlice;

export const selectContinueWatchingItems = createSelector(
  (rootState: RootState) => {
    return rootState.continueWatching;
  },
  (continueWatching) => {
    return continueWatching.items;
  },
);
