import { Modal } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as ls from 'local-storage';
import { FunctionComponent, useEffect, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Action } from 'redux';

import { useAppDispatch, useAppSelector } from '@@src/hooks/store';
import favouritesStore, { listFavouritesAsyncThunk } from '@@stores/FavouritesStore';
import userActivityStore, { getProgressAsyncThunk } from '@@stores/UserActivityStore';
import userStore, { getUserId, UserData } from '@@stores/UserStore';
import { createSbsLoginInstance } from '@@utils/SbsLoginUtils';
import { V3_API_HOST } from '@@utils/constants';
import { transformAuthApiHost } from '@@utils/helpers';
import Logger from '@@utils/logger/Logger';

import { getLocalizedPath, supportedLangCodes, SupportedLanguage } from '../../i18n';

const useStyles = makeStyles(() => {
  return createStyles({
    loginModal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&.visible': {
        overflow: 'scroll',
      },
    },
    loginContainer: {
      maxWidth: 600,
      position: 'relative',
      margin: 'auto',
      flex: 1,
      overflowX: 'auto',
      '&:focus-visible': {
        outline: 'none',
      },
    },
  });
});

let sbsLogin;

export const getSessionId = (): string => {
  return sbsLogin ? sbsLogin.getSessionId() : null;
};

export const hasSessionId = (): boolean => {
  return !!getSessionId();
};

export function dispatchSignOut(): void {
  const event = new Event('OdWebsite_SignOut');
  document.dispatchEvent(event);
}

export function dispatchSignInSuccess(): void {
  const event = new Event('OdWebsite_SignInSuccess');
  document.dispatchEvent(event);
}

export function dispatchSignOutSuccess(): void {
  const event = new Event('OdWebsite_SignOutSuccess');
  document.dispatchEvent(event);
}

export function dispatchOpenSignIn(postLoginAction: Action = null): void {
  const event = new CustomEvent('OdWebsite_OpenSignIn', {
    detail: { postLoginAction },
  });
  document.dispatchEvent(event);
}

export function dispatchCloseSignIn(): void {
  const event = new Event('OdWebsite_CloseSignIn');
  document.dispatchEvent(event);
}

export function dispatchOpenCreateAccount(): void {
  const event = new Event('OdWebsite_OpenCreateAccount');
  document.dispatchEvent(event);
}

const LoginForm: FunctionComponent = () => {
  const classes = useStyles({});
  const { t, i18n: { language } } = useTranslation('common');
  const dispatch = useAppDispatch();
  const location = useLocation();
  const locationRef = useRef(location);
  locationRef.current = location;
  const modalRef = useRef<HTMLDivElement>(null);

  const [shouldRender, setShouldRender] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean | 'signin' | 'create'>(false);

  const userId = useAppSelector(getUserId);

  useEffect(() => {
    setShouldRender(true);
  }, []);

  useEffect(() => {
    if (openModal) {
      sbsLogin.attach('#sbs-login');
      if (openModal === 'create') {
        sbsLogin.openCreateAccount();
        document.getElementById('sbs-login-firstname').focus();
      } else {
        sbsLogin.open();
        document.getElementById('sbs-login-email').focus();
      }
    }
  }, [openModal]);

  useEffect(() => {
    if (userId) {
      dispatch(getProgressAsyncThunk());
      dispatch(listFavouritesAsyncThunk());
    }
  }, [userId, dispatch]);

  // close the login form when location changes
  useEffect(() => {
    setOpenModal(false);
  }, [setOpenModal, location.key]);

  /* istanbul ignore next */
  const setUserData = useCallback((userData: UserData) => {
    dispatch(userStore.actions.setUserData(userData));
  }, [dispatch]);

  useEffect(() => {
    let sbsLoginInstance;

    if (!sbsLogin) {
      /* @todo: refactor to make this testable - it's very difficult to test because it's nested inside useEffect,  */
      sbsLoginInstance = createSbsLoginInstance({
        apiHost: V3_API_HOST,
        authApiHost: transformAuthApiHost(process.env.BVAR_AUTH_API_HOST),
        context: 'odwebsite',
        language,
        loadFonts: false,
        showGeoBlockWarning: true,
        enableLegacyEnsightenDataLayer: false,
      });

      // events
      /* istanbul ignore next */
      sbsLoginInstance.on('signInSuccess', (loginData) => {
        Logger.info('signInSuccess callback');

        setUserData({
          sessionId: loginData.sessionId,
          id: loginData.profile.uuid,
          name: loginData.profile.givenName,
          emailHash: loginData.emailHash,
        });

        setOpenModal(false);

        // if language was changed, redirect to the language location
        const sbsLoginLanguage: SupportedLanguage = sbsLoginInstance.getLanguage() as SupportedLanguage;
        if (sbsLoginLanguage !== language) {
          if (supportedLangCodes.includes(sbsLoginLanguage)) {
            window.location.href = getLocalizedPath(sbsLoginLanguage, locationRef.current);
          } else {
            // redirect to english if not supported
            window.location.href = getLocalizedPath('en', locationRef.current);
          }
        }

        dispatchSignInSuccess();
      });

      /* istanbul ignore next */
      sbsLoginInstance.on('signOutSuccess', () => {
        Logger.info('signOutSuccess callback');
        dispatch(favouritesStore.actions.clear());
        dispatch(userActivityStore.actions.clear());
        dispatch(userStore.actions.clear());

        dispatchSignOutSuccess();
      });

      /* istanbul ignore next */
      sbsLoginInstance.on('ready', () => {
        Logger.info('ready callback');
      });

      /* istanbul ignore next */
      sbsLoginInstance.on('close', () => {
        Logger.info('close callback');
        dispatchCloseSignIn();
        setOpenModal(false);
        const sbsLoginLanguage: SupportedLanguage = sbsLoginInstance.getLanguage() as SupportedLanguage;
        if (sbsLoginLanguage !== language && supportedLangCodes.includes(sbsLoginLanguage)) {
          window.location.href = getLocalizedPath(sbsLoginLanguage, locationRef.current);
        }
      });

      sbsLogin = sbsLoginInstance;

      if (sbsLoginInstance.isLoggedIn()) {
        /* istanbul ignore next */
        sbsLoginInstance.getEmailHash().then((emailHash) => {
          setUserData({
            sessionId: sbsLoginInstance.getSessionId(),
            id: sbsLoginInstance.getUserId(),
            name: sbsLoginInstance.getUserName(),
            emailHash,
          });
        });
      } else {
        setUserData({
          sessionId: null,
          id: null,
          name: null,
          emailHash: null,
        });
      }
    }

    if (modalRef.current) {
      sbsLoginInstance.attach('#sbs-login');
    }

    /* istanbul ignore next */
    const openSignInHandler = (e: CustomEvent) => {
      Logger.info('Event captured', { type: e.type });
      setOpenModal('signin');

      const dispatchPostLoginAction = () => {
        dispatch(e.detail.postLoginAction);
      };

      const removeDispatchPostLoginAction = () => {
        sbsLogin.off('signInSuccess', dispatchPostLoginAction);
        sbsLogin.off('close', removeDispatchPostLoginAction);
      };

      if (sbsLogin) {
        if (e.detail.postLoginAction) {
          sbsLogin.on('signInSuccess', dispatchPostLoginAction);
          sbsLogin.on('close', removeDispatchPostLoginAction);
        }
      }
    };
    document.addEventListener('OdWebsite_OpenSignIn', openSignInHandler);

    /* istanbul ignore next */
    const closeSignInHandler = () => {
      setOpenModal(false);
    };
    document.addEventListener('OdWebsite_CloseSignIn', closeSignInHandler);

    /* istanbul ignore next */
    const openCreateAccountHandler = (e) => {
      Logger.info('Event captured', { type: e.type });
      setOpenModal('create');
    };

    document.addEventListener('OdWebsite_OpenCreateAccount', openCreateAccountHandler);

    /* istanbul ignore next */
    const signOutHandler = (e) => {
      Logger.info('Event captured', { type: e.type });
      sbsLogin.signOut();
      setUserData({
        sessionId: null,
        id: null,
        name: null,
        emailHash: null,
      });

      // remove player preferences
      ls.backend(localStorage);
      ls.remove('od.player.userPrefs');
    };
    document.addEventListener('OdWebsite_SignOut', signOutHandler);

    return function cleanup() {
      document.removeEventListener('OdWebsite_OpenSignIn', openSignInHandler);
      document.removeEventListener('OdWebsite_OpenCreateAccount', openCreateAccountHandler);
      document.removeEventListener('OdWebsite_SignOut', signOutHandler);
    };
  }, [dispatch, language, setUserData]);

  const handleModalClose = () => {
    dispatchCloseSignIn();
    setOpenModal(false);
  };

  return (
    <>
      {shouldRender && (
        <Modal
          ref={modalRef}
          id="login-modal"
          tabIndex={-1}
          role="dialog"
          aria-modal="true"
          aria-label={t('login.loginForm')}
          aria-labelledby="sbs-login-heading"
          className={clsx(classes.loginModal, openModal && 'visible')}
          open={!!openModal}
          onClose={handleModalClose}
          keepMounted
        >
          <div className={classes.loginContainer}>
            <div id="sbs-login"/>
          </div>
        </Modal>
      )}
    </>
  );
};

export default LoginForm;
